<template>
  <div class="login-page" @click="down">
    <!-- <div class="header" style="width:100%; margin:0; padding:0;">
      <img src="../../assets/111.png"  style="width:100%;" />
    </div> -->
    <div class="footer" style="width:100%; margin:0; padding:0;">
      <img src="../../assets/333.png"  style="width:100%;" />
    </div>
    <div class="copy-right">嗨皮直播 桂ICP备2022003788号 <br /> &copy; 2023 南宁多维互联科技有限公司 版权所有 </div>

    <van-tabbar>
      <div style="width:100%">
        <van-button size="large" color="linear-gradient(to right, #9957f7, #c09cf4)">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="2000"
            :touchable="false"
            :show-indicators="false"
          >
            <van-swipe-item>点我 马上下载 嗨皮直播</van-swipe-item>
            <van-swipe-item>同城的Ta 正在等你哦 开始交友吧</van-swipe-item>
          </van-swipe>
        </van-button>

      </div>
    </van-tabbar>

  </div>
</template>
<script>
import { Tabbar, Button, Swipe, SwipeItem } from 'vant';
export default {
  name: "jfDown",
  components: {
    [Tabbar.name]: Tabbar,
    [Button.name]: Button,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  methods: {
    down() {
      window.location.href = "https://happy-dwhl.oss-cn-hangzhou.aliyuncs.com/happy_v405_jianfeng_release.apk"
    }
  }
};
</script>

<style lang="scss">
  .copy-right {
    color: rgba(22, 18, 62, .5);
    text-align: center;
    padding: 20px 0 80px 0;
  }
  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
</style>
